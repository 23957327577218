<template>
  <div id="chaxunh">
    <top :name="text" />
    <div class="two">
      <p class="block" @click="rengong">进入南航人工选座</p>
      <p class="block" @click="chaxunH">小程序订单查询</p>
      <!-- 导出模板 -->

      <template>
        <download-excel
          class="export-excel-wrapper"
          :data="DetailsForm"
          :fields="json_fields"
          header="南航选座收益报表"
          name="南航选座收益报表.xls"
        >
          <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
          <el-button type="success">导出</el-button>
        </download-excel>
      </template>
    </div>
    <div class="two">
      <input v-model="value" type="text" placeholder="姓名/票号" />
      <p @click="sousuo" class="block">搜索</p>
      <p @click="all" class="block">显示全部数据</p>
    </div>
    <!-- 订单数据表格 全部数据 -->
    <div style="overflow: auto; height: 75vh" v-show="all_show">
      <table border="1" style="font-size: 0.3rem; overflow: auto">
        <tr>
          <td>姓名</td>
          <td>第一个票号</td>
          <td>座位</td>
          <td>时间</td>
          <td>原始里程</td>
          <td>实际里程</td>
          <td>卡号</td>
          <td>卡密</td>
        </tr>
        <tr v-for="(item, index) in res" :key="index">
          <td>
            <span v-for="(it, ind) in item.xinxi" :key="ind"
              >{{ it.name }},</span
            >
          </td>
          <td>{{ item.xinxi[0].piao }}</td>
          <td>
            <span v-for="(it, ind) in item.xinxi" :key="ind"
              >{{ it.zuo }},</span
            >
          </td>
          <td>{{ item.time }}</td>
          <td>{{ item.pay_licheng }}</td>
          <td>{{ item.shiji }}</td>
          <td>{{ item.admin }}</td>
          <td>{{ item.pwd }}</td>
        </tr>
      </table>
    </div>

    <!-- 订单数据表格 搜索数据 -->
    <div style="overflow: auto; height: 75vh" v-show="sousuo_show">
      <table border="1" style="font-size: 0.3rem; overflow: auto">
        <tr>
          <td>姓名</td>
          <td>第一个票号</td>
          <td>座位</td>
          <td>时间</td>
          <td>原始里程</td>
          <td>实际里程</td>
          <td>卡号</td>
          <td>卡密</td>
        </tr>
        <tr v-for="(item, index) in sousuo_data" :key="index">
          <td>
            <span v-for="(it, ind) in item.xinxi" :key="ind"
              >{{ it.name }},</span
            >
          </td>
          <td>{{ item.xinxi[0].piao }}</td>
          <td>
            <span v-for="(it, ind) in item.xinxi" :key="ind"
              >{{ it.zuo }},</span
            >
          </td>
          <td>{{ item.time }}</td>
          <td>{{ item.pay_licheng }}</td>
          <td>{{ item.shiji }}</td>
          <td>{{ item.admin }}</td>
          <td>{{ item.pwd }}</td>
        </tr>
      </table>
    </div>

    <!-- 订单数据表格 -->
  </div>
</template>

<script>
import top from "../components/filltop.vue";
export default {
  components: {
    top,
  },
  data() {
    return {
      text: "H5选座查询",
      data: [],
      res: [],
      json_fields: {
        姓名: "name",
        票号: "piao",
        座位: "zuo",
        原本支出里程: "pay_licheng",
        实际支出里程: "shiji",
        卡号: "admin",
        卡密: "pwd",
      },
      DetailsForm: [
        {
          name: "",
          piao: "",
          zuo: "",
          pay_licheng: "",
          shiji: "",
          admin: "",
          pwd: "",
        },
      ],
      value: "",
      all_show: true,
      sousuo_show: false,
      sousuo_data: [],
    };
  },
  mounted() {
    this.$axios({
     url : "https://wx.yaolvxing.com/selection/ren/chaxunh",
     method : 'GET',
     headers:{
      token : 111
     }
    }).then((res) => {
        this.res = res.data.data;
      });
    // this.$axios
    //   .get("https://wx.yaolvxing.com/selection/ren/table", "")
    //   .then((res) => {
    //     this.DetailsForm = res.data.data;
    //     console.log();
    //   });
  },
  methods: {
    rengong() {
      this.$router.push({
        name: "inset",
      });
    },
    chaxunH() {
      this.$router.push({
        name: "xz_index",
      });
    },
    href() {},
    sousuo() {
      this.sousuo_data = [];
      for (let i in this.res) {
        for (let b in this.res[i].xinxi) {
          // 姓名票号
          if (
            this.res[i].xinxi[b].name == this.value ||
            this.res[i].xinxi[b].piao == this.value
          ) {
            console.log(this.res[i]);
            this.sousuo_data.push(this.res[i]);
            this.all_show = false;
            this.sousuo_show = true;
          }
        }
      }
    },
    all() {
      this.all_show = true;
      this.sousuo_show = false;
    },
  },
};
</script>



<style scoped>
p {
  font-size: 0.3rem;
}
.xinxi {
  display: flex;
  justify-content: center;
}
.dis_san {
  width: 100vw;
  padding-top: 0.2rem;
  height: auto;
}
.dis_san div {
  width: 100vw;
  display: flex;
  justify-content: space-around;
}
.dis_san div p {
  margin-bottom: 0.1rem;
  display: block;
  text-align: center;
}
tr {
  text-align: center;
  padding: 0.2rem;
}
td {
  padding: 0.1rem;
}
.mian {
}
.fu {
  background: rgb(11, 78, 104);
  color: white;
}
.block {
  margin: 0.2rem;
  display: block;
  padding: 0.2rem;
  /* width: 20VW; */
  text-align: center;
  background: rgb(1, 42, 58);
  color: white;
}
.two {
  display: flex;
  align-content: center;
  justify-content: space-around;
}
</style>